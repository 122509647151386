import React from 'react'
import { getLvpLayout } from '@chegg-wt/core/components/Layouts/LargeVideoPlayerLayout'
import { NextPageWithLayout } from '@chegg-wt/core/types/layout'
import SEOTitle from '@chegg-wt/core/components/SEOTitle'
import useUserRole from '@chegg-wt/core/hooks/useUserRole'
import useRedirect from '@chegg-wt/core/hooks/useRedirect'
import { useRoutesContext } from '@chegg-wt/core/components/RoutesContext'
import { UserRoles } from '@chegg-wt/core/constants/user'
import WritingAssistantHome from '@chegg-wt/core/components/Layouts/WritingAssistantHome'
import { useOnUnloadTimeOnPage } from '@chegg/time-on-page'
import resources from '../copy/resources'

const HomePage: NextPageWithLayout = () => {
  const userRole = useUserRole()
  const { toDashboard } = useRoutesContext()
  useRedirect({
    shouldRedirect: userRole === UserRoles.PRO_USER,
    redirectRoute: toDashboard(),
  })
  useOnUnloadTimeOnPage()

  return (
    <>
      <SEOTitle title="EasyBib®: Free Bibliography Generator - MLA, APA, Chicago citation styles" />
      <WritingAssistantHome copy={{ resources }} />
    </>
  )
}

HomePage.getPageLayout = (page) => getLvpLayout(page, () => <div />, false, false, true)

export default HomePage
